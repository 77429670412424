import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
/*import { AgmCoreModule } from '@agm/core';*/

import { AppComponent } from './app.component';

import { HttpClientModule } from "@angular/common/http";
import { UserAuthGuard } from "app/user-authentication/user-auth.guard";
/*import { DisclaimerComponent } from "app/footer/disclaimer/disclaimer.component";
import { ConditionsComponent } from "app/footer/conditions/conditions.component";
import { HelpComponent } from "app/footer/help/help.component";
import { PrivacyComponent } from "app/footer/privacy/privacy.component";
import { PaymentComponent } from "app/footer/payment/payment.component";
import { RefundComponent } from "app/footer/refund/refund.component";*/
import { environment } from "environments/environment";
import { LodgeAuthGuard } from "app/user-authentication/Lodge-auth.guard";
import { UserComponent } from './user/user.component';
import { TransactionComponent } from './user/transaction/transaction.component';
/*if (environment.production) {
    enableProdMode();
  }*/

const appRoutes: Routes = [
    { path: '', redirectTo: 'management', pathMatch: 'full' },
    {
        path: 'management',

        loadChildren: './management/management.module#ManagementModule',
    }
 ];

@NgModule( {
    declarations: [
        AppComponent,
        UserComponent,
        TransactionComponent,
       


    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        
        /*   AgmCoreModule.forRoot( { apiKey: 'AIzaSyBwglMNXTtRXOMLxVR7M_DtoF1wEbTiVMg' } ),*/
        RouterModule.forRoot(
            appRoutes,
            { enableTracing: true,useHash:true } // <-- debugging purposes only
        )
    ],
    providers: [UserAuthGuard,LodgeAuthGuard],
    bootstrap: [AppComponent]
} )
export class AppModule { }
