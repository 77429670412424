 
import { RoleMasterPojo } from "app/management/rolemaster/rolemaster.pojo";

export class LodgeUser{   
    lodgeUserId: number;
    userId: number;
    userName: string;
    password: string;
    regionId: number;
    cottageId: number;
 roleId:string;

 roleKey: RoleMasterPojo;
companyId:number;
 }