export class RoleFuncPojo {
    
    master= false;
    lodgeapp=false;
    checkin=false;
    
    roomstatus= false;
    roomcleaning= false;
    checkinhistory=false;
    roomtransfer=false;
    onlinebooking = false;
    roomtransferform = false;
    roomtransferhistory = false;
    lodgereport = false;
    cottagereport=false;
    roomreport=false;
    lodgesettings=false;
    roomblocking=false;
    billmaster=false;
    chittatable=false;
    
}