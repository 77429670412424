import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LodgeLoginPojo } from "app/management/LodgeCheckIn/lodge-login/lodge-login.pojo";
import { RoleMasterPojo } from "app/management/rolemaster/rolemaster.pojo";
import { RoleFuncPojo } from "app/management/rolemaster/rolemaster.funcpojo";
import { LodgeUser } from "app/management/LodgeCheckIn/lodge-user/lodge-user.pojo";

@Injectable()
export class LodgeAuthGuard implements CanActivate {
    //loggedUser = new LodgeLoginPojo();
    loggedUser = new LodgeUser();
    role = new RoleFuncPojo();
    
    constructor( private router: Router ) { }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
        let user = sessionStorage.getItem( 'palaniTempleManagementLodgeDetail');
      //alert(user);
        if(user != null){
           // alert()
        this.loggedUser = JSON.parse(user);
        this.role = JSON.parse(this.loggedUser.roleKey.roleKey);
       // alert(JSON.stringify(this.role))
        //alert(route.data.roles);
         switch(route.data.roles){
         
         case 'lodgeapp':
             if(this.role.lodgeapp){
                 return true;
             }
             break;
        case 'checkin':
            if(this.role.checkin){
                return true;
            }
            break;
        case 'roomstatus':
            if(this.role.roomstatus){
                    return true;
            }
            break;
        case 'roomcleaning':
            if(this.role.roomcleaning){
                    return true;
            }
            break;
        case 'checkinhistory':
            if(this.role.checkinhistory){
                    return true;
            }
            break;
        case 'roomtransfer':
            if(this.role.roomtransfer){
                    return true;
            }
            break;
        case 'onlinebooking':
            if(this.role.onlinebooking){
                    return true;
            }
            break;
        case 'roomtransferhistory':
            if(this.role.roomtransferhistory){
                    return true;
            }
            break;
        case 'lodgereport':
            if(this.role.lodgereport){
                    return true;
            }
            break;
        case 'cottagereport':
            if(this.role.cottagereport){
                    return true;
            }
            break;
        case 'roomreport':
            if(this.role.roomreport){
                return true;
            }
            break;
        case 'lodgesettings':
            if(this.role.lodgesettings){
                return true;
            }
            break;
        case 'roomblocking':
            if(this.role.roomblocking){
                return true;
            }
            break;
        case 'master':
            if(this.role.master){
                return true;
            }
            break;
      
            
        case 'profile':
                return true;
        }
        }
        else{
            alert("Access denied...");
            return true;
        }
      
      }
}
